<template>
	<div class="main-content">
		<!-- BradCrumb header End -->
		<!-- Blog Grid Section Start -->
		<section class="section">
			<div class="container">
				<div class="blog-post-grid">
					<div class="row">
						<!-- Start Blog Item -->
						<div
							class="col-md-6 col-lg-4"
							v-for="(data, index) in newsitems"
							:key="index"
						>
							<v-card
								color="rgb(50, 50, 50, 1.0)"
								elevation="24"
								class="rounded-card"
							>
								<div class="blog-item mb-5">
									<router-link class="blog-post-image" to="#">
										<img
											:src="data.image.url"
											alt="blog-img"
											class="img-fluid w-100"
										/>
									</router-link>
									<div class="blog-post-info">
										<h4 class="blog-post-title">
											<!-- <router-link class="text-dark" :to="{name: 'News', params: { id: data.shorttitle, image: data.image.url, text:data.text.html, title:data.title, summary:data.summary, date:data.date },}"> -->
											<div style="color: white">{{ data.title }}</div>
											<router-link :to="geturl(data.shorttitle)">
												<div>
													<br />
													<a>
														<h6 style="color: #ffd700">Read Full Story</h6>
													</a>
												</div>
											</router-link>
											<!-- </router-link> -->
										</h4>
										<p style="color: white">{{ data.summary }}</p>
										<div class="post-event d-flex align-items-center">
											<a style="color: white" href="#" class="blog-info-meta">{{
												data.date
											}}</a>
											<a style="color: white" href="#" class="blog-category">{{
												data.category
											}}</a>
										</div>
										<div class="center">
											<!-- <router-link  :to="{name: 'News', params: { id: data.shorttitle, image: data.image.url, text:data.text.html, title:data.title, summary:data.summary, date:data.date },}">
										<div> <br> <a> <h6 style="color:#FFD700"> Read Full Announcement </h6> </a> </div>
                                    </router-link> -->
										</div>
									</div>
								</div>
							</v-card>
						</div>
						<!-- End Blog Item -->
					</div>
				</div>
				<!-- <div class="pagination  d-flex justify-content-center mt-lg-5">
                    <ul class="pagination mb-0">
                        <li class="page-item text-center">
                            <a href="#" class="page-link rounded-0">
                                <i class="mdi mdi-chevron-left"></i>
                            </a>
                        </li>
                        <li class="page-item text-center active">
                            <a href="#" class="page-link rounded-0">1</a>
                        </li>
                        <li class="page-item text-center">
                            <a href="#" class="page-link rounded-0">
                                <i class="mdi mdi-chevron-right"></i>
                            </a>
                        </li>
                    </ul>
                </div> -->
			</div>
		</section>
		<!-- Blog Grid Section End -->
	</div>
</template>
<script>
export default {
	name: "blog",
	data() {
		return {
			newsitems: [],
		};
	},
	async created() {
		// Get Data from the API
		// gql`
		//     {
		//       newsitems(orderBy: createdAt_DESC) {
		//         title
		//         shorttitle
		//         date
		//         summary
		//         text {
		//           raw
		//           html
		//           markdown
		//           text
		//         }
		//         category
		//         image {
		//           url
		//         }
		//       }
		//     }
		//   `
		// )
		this.newsitems = data.newsitems.slice(0, 50);
		for (let i = 0; i < this.newsitems.length; i++) {
			this.newsitems[i].date = this.dateformat(this.newsitems[i].date);
		}
	},
	methods: {
		dateformat(date) {
			var dt = new Date(date);
			return (
				(dt.getMonth() + 1).toString() +
				"/" +
				(dt.getDate() + 1).toString() +
				"/" +
				dt.getFullYear().toString()
			);
		},
		geturl(title) {
			return "/newsdetail?title=" + title;
		},
	},
};
</script>
<style scoped>
.center {
	margin: 0;
	position: relative;
	top: 105%;
	left: 50%;
	-ms-transform: translate(-50%, -50%);
	transform: translate(-50%, -50%);
}
.blog-item {
	position: relative;
	height: 400px;
	overflow-y: scroll;
}

.blog-post-title {
	font-size: 18px;
	margin-top: 18px;
	margin-bottom: 15px;
}

.blog-post-info p,
.post-event a {
	font-size: 14px;
}

.blog-post-info p {
	margin-bottom: 14px;
}

.blog-category {
	position: relative;
	padding-left: 15px;
	margin-left: 10px;
}

.blog-category::before {
	content: "";
	position: absolute;
	top: 5px;
	left: 0;
	height: 12px;
	width: 2px;
	background-color: #969696;
}

.blog-btn {
	padding: 10px 20px;
}

.blog-post-image {
	display: block;
	overflow: hidden;
	position: relative;
}

.blog-post-image::after {
	content: "";
	width: 0;
	height: 4px;
	bottom: 0;
	position: absolute;
	left: auto;
	right: 0;
	-webkit-transition: width 0.6s cubic-bezier(0.25, 0.8, 0.25, 1) 0s;
	-o-transition: width 0.6s cubic-bezier(0.25, 0.8, 0.25, 1) 0s;
	transition: width 0.6s cubic-bezier(0.25, 0.8, 0.25, 1) 0s;
	background: #f2b636;
}

.blog-item:hover .blog-post-image::after {
	width: 100%;
	left: 0;
	right: auto;
}

.blog-post-image img {
	-webkit-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
	-o-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
	transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.blog-item:hover .blog-post-image img {
	width: 100%;
	-webkit-transform: scale3d(1.1, 1.1, 1.1);
	transform: scale3d(1.1, 1.1, 1.1);
}

.pagination li {
	margin: 0 5px;
}

.pagination li a {
	height: 40px;
	width: 40px;
	display: flex;
	vertical-align: top;
	background: #314584;
	color: #fff;
	border: #314584;
	justify-content: center;
	align-items: center;
}

.page-item.active .page-link,
.page-item:hover .page-link {
	background: #f2b636;
	border: #f2b636;
	color: #fff;
}

.blog-post-info {
	padding: 0 20px 50px;
}

.blog-read-more {
	position: absolute;
	bottom: -30px;
	left: 50%;
	-webkit-transform: translate(-50%);
	-ms-transform: translate(-50%);
	transform: translate(-50%);
	font-size: 20px;
	width: 60px;
	height: 60px;
	line-height: 60px;
	border-radius: 50%;
	background-color: #fff;
	border: 2px solid #314584;
	color: #314584;
	display: flex;
	justify-content: center;
	align-items: center;
	-webkit-transition: all 0.3s ease;
	transition: all 0.3s ease;
	z-index: 1;
}

.blog-item:hover .blog-read-more {
	background-color: #f2b636;
	border: 2px solid #f2b636;
	color: #fff;
}
</style>
